/* push footer to bottom */
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

/* expand main content to push footer to bottom */
.my-container {
  flex: 1 0 auto;
}

.nav-title {
  font-family: 'MedievalSharp', cursive;
}

.refresh-icon {
  float: right;
  padding-top: 8px;
  cursor: pointer;
}

.mylist-item a {
  text-decoration: none;
  color: #212529;
}

.mylist-item i,
.mylist-item span {
  float: right;
}

.mylist-item:hover {
  background-color: #f7f7f7;
}

#star:hover {
  color: gold;
  cursor: pointer;
}

#bookmark:hover {
  color: red;
  cursor: pointer;
}

#visit:hover {
  color: green;
}

#score:hover {
  cursor: default;
}

.favlist-item a {
  text-decoration: none;
}

.trash-icon:hover {
  color: red;
  cursor: pointer;
}

.tooltips {
  font-size: 16px !important;
}